import { Injectable } from '@angular/core';
import { Roles } from 'src/app/model/variables';

const rol = Roles;

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  permiso?: Array<Number>;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    permiso: [rol.administrador, rol.oficial, rol.partido, rol.partidoLocal],
    children: [
      {
        id: 'inicio',
        title: 'Inicio',
        type: 'item',
        classes: 'nav-home',
        url: '/inicio',
        icon: 'feather icon-sidebar',
        permiso: [rol.administrador],
      },
      {
        id: 'peticiones',
        title: 'Peticiones',
        type: 'collapse',
        // url: '/inicio',
        // classes: 'nav-item',
        icon: 'feather icon-sidebar',
        permiso: [rol.administrador, rol.oficial, rol.partido, rol.partidoLocal],
        children: [
          {
            id: 'seguimiento',
            title: 'Seguimiento',
            type: 'item',
            url: '/peticiones/seguimiento',
            classes: 'nav-item',
            permiso: [rol.administrador, rol.oficial, rol.partido, rol.partidoLocal],
          },
          {
            id: 'nueva',
            title: 'Nueva petición',
            type: 'item',
            url: '/peticiones/nueva',
            classes: 'nav-item',
            permiso: [rol.administrador, rol.oficial, rol.partido, rol.partidoLocal],
          },
        ]
      },
    ]
  },
  {
    id: 'copias',
    title: 'Copias',
    type: 'group',
    icon: 'feather icon-monitor',
    permiso: [rol.administrador],
    children: [
      {
        id: 'copias',
        title: 'Copias Certificadas',
        type: 'item',
        url: '/administrador/copias',
        classes: 'nav-item',
        icon: 'feather icon-layers',
        permiso: [rol.administrador, rol.oficial],
      }
    ]
  },
  {
    id: 'administrador',
    title: 'Administrador',
    type: 'group',
    icon: 'feather icon-monitor',
    permiso: [rol.administrador],
    children: [
      {
        id: 'administrar',
        title: 'Administrar',
        type: 'collapse',
        icon: 'feather icon-list',
        permiso: [rol.administrador],
        children: [
          {
            id: 'usuarios',
            title: 'Usuarios',
            type: 'item',
            url: '/administrador/usuarios',
            classes: 'nav-item',
            permiso: [rol.administrador],
          },
          {
            id: 'organos',
            title: 'Organos',
            type: 'item',
            url: '/administrador/organos',
            classes: 'nav-item',
            permiso: [rol.administrador],
          },
          {
            id: 'improcedencias',
            title: 'Improcedencias',
            type: 'item',
            url: '/administrador/improcedencias',
            classes: 'nav-item',
            permiso: [rol.administrador],
          },
          {
            id: 'prevenciones',
            title: 'Prevenciones',
            type: 'item',
            url: '/administrador/prevenciones',
            classes: 'nav-item',
            permiso: [rol.administrador],
          },
          {
            id: 'articulos',
            title: 'Artículos',
            type: 'item',
            url: '/administrador/articulos',
            classes: 'nav-item',
            permiso: [rol.administrador],
          }
        ]
      },
    ]
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
