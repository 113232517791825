import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from "./../service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private _auth :AuthenticationService, private _router: Router) {
  }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let rol = localStorage.getItem('rol');
    let allow = rol.indexOf(next.data.rolUsuario) > -1;
    let permiso:boolean = false

    next.data.rolUsuario.forEach(e => {
      if(e == rol)
      permiso = true;
    });

    if (permiso)
      return true;

    if (allow == false)
      this._router.navigate(['/peticiones/seguimiento']);

    return allow;
  }
}
