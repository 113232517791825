import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParametrosService } from './parametros.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

const _url = environment.ruta

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {

  constructor(private _http: HttpClient, private _valor: ParametrosService) { }

  // Obtener Informacion
  public obtenerSolicitudes(){
    let encabezado = this._valor.getToken();
    return this._http.get(`${_url}Solicitudes/`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Obtener Informacion de todas las solicitudes terminadas
  public obtenerSolicitudesFinalizadas(){
    let encabezado = this._valor.getToken();
    return this._http.get(`${_url}Solicitudes/Finalizadas/`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Obtener Informacion detalle
  public obtenerDetallePeticion(idSolicitud: number) {
    let encabezado = this._valor.getToken();
    return this._http.get(`${_url}Solicitudes/${idSolicitud}`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Obtener Informacion detalle Copia Certificadas
  public obtenerCopiasDetallePeticion(idSolicitud: number) {
    let encabezado = this._valor.getToken();
    return this._http.get(`${_url}copiacertificada/${idSolicitud}`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public guardarPeticiones(info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para Admitir la peticion
  public accionPeticionAdmitir(idSolicitud) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Admitir`, '', { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para Desechar la peticion
  public accionPeticionDesechar(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Desechar`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para Cancelar la peticion
  public accionPeticionCancelar(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Cancelar`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para peticion requerir
  public accionPeticionRequerir(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Prevencion`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para peticion Responder Requerir
  public accionPeticionResponderRequerir(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Atencion`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para peticion Diligencia
  public acciondiligencia(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Diligencia`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

   // Accion para peticion Diligencia
   public accionCopiaCertificada(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}CopiaCertificada/${idSolicitud}`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para peticion Actualizar
  public accionActualizarCopia(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Finalizar`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public accionReemplazar(id, info) {
    let encabezado = this._valor.getToken();
    return this._http.put(`${_url}CopiaCertificada/${id}`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para peticion Reasignr
  public accionReasignar(info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/Reasignar`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para peticion Reasignr
  public accionAdmicionParcial(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/AdmitirParcial`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  // Accion para peticion Reasignr
  public accionSubirActa(idSolicitud, info) {
    let encabezado = this._valor.getToken();
    return this._http.post(`${_url}Solicitudes/${idSolicitud}/Acta`, info, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  private infoDatos(res: Response) {
    let body: any = res;
    return body.info || {};
  }
}
