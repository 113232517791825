export class Copias {
    "clave": string = "";
    "claveActa": string = "";
    "claveCopia": string = "";
    "idSolicitud": number = null;
    "idTipoSolicitud": number = null;
    "CopiasCertificadas" : CopiasCertificadas[] = [];
}

export class CopiasCertificadas {
    "idCopiaCertificada": number = 0;
    "adjunto": string = "";
    "anexoFile": File = null;
    "fechaExpedida": string = "";
    "entregaFecha": string = "";
    "entregaHora": string = "";
    "fechaHoraEntrega": string = "";
    "fechaHoraRegistro": string = "";
    "folio": string = "";
    "nombrePeticionario": string = "";
    "url": string = "";
    "idSolicitud": number = 0;
}