import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import {  AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';


import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';

// Plantilla de administrador
import { AdminComponent } from './view/layout/admin/admin.component';
import { AuthComponent } from './view/layout/auth/auth.component';
import { NavigationComponent } from './view/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './view/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './view/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './view/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './view/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './view/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './view/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './view/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './view/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './view/layout/admin/configuration/configuration.component';
import { ToggleFullScreenDirective } from './view/shared/full-screen/toggle-full-screen';

// Guardian
import { AuthGuard } from './data/guard/auth.guard';
// Componentes para compartir
import { SharedModule } from './view/shared/shared.module';
// Navegacion de Items
import { NavigationItem } from './view/layout/admin/navigation/navigation';

import { JwtInterceptor, ErrorInterceptor } from './data/helpers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CustomDateParserFormatter, CustomAdapter } from './data/helpers/date.intercerptor';
import { RoleGuard } from './data/guard/role.guard';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#00ACC1',
  bgsOpacity: 0.4,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'square-jelly-box',
  blur: 8,
  fgsColor: '#e12362',
  fgsPosition: 'center-center',
  fgsSize: 80,
  fgsType: 'square-jelly-box',
  gap: 55,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#e12362',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: false,
  text: 'Cargando...',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  // threshold: 500
};

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbTabsetModule,
    HttpClientModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBQip0bpL2LM6qR_xx2CHYJ6wmfOD9HWGo',
      libraries: ['places']
    }),
    AgmSnazzyInfoWindowModule,
    AgmJsMarkerClustererModule
  ],
  providers: [
    NavigationItem,
    AuthGuard,
    RoleGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorInterceptor },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
