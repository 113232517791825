import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {

  public usuario = '';

  constructor(private _router: Router) { }

  ngOnInit() {
    this.usuario = localStorage.getItem('nombre_usuario')
  }

  cerrarSesion(){
    this._router.navigate(['/login']);
    localStorage.clear();
  }
  
  iniciarSesion(){
    this._router.navigate(['/login']);
  }
}
