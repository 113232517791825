import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParametrosService } from './parametros.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

const _url = environment.ruta

@Injectable({
  providedIn: 'root'
})
export class CatalogosService {

  constructor(private _http: HttpClient, private _valor: ParametrosService) { }

  public CatalogoMunicipios() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/Organos/Municipios`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public CatalogoPartidos() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/Partidos`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public CatalogoPeticionarios() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/Peticionarios`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public CatalogoCaracter() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/Caracteres`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public CatalogoTiposImprocedencia() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/TiposImprocedencias`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public CatalogoOrgnos() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/Organos`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public CatalogoTiposPrevenciones() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/TiposPrevenciones`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  public CatalogoArticulos() {
    let encabezado = this._valor.FormDataToken();
    return this._http.get(`${_url}Catalogos/Articulos`, { headers: encabezado }).pipe(map(this.infoDatos));
  }

  private infoDatos(res: Response) {
    let body: any = res;
    return body.info || {};
  }
}
